
import { defineComponent, onMounted, ref } from 'vue';
import UITable from '@/components/UI/UITable.vue';
import { makeRequest, METHODS, requestFile } from '@/utils/apiUtils';
import { IReport } from '@/models/Report';
import moment from 'moment';
import { showToast } from '@/hooks/useToast';

interface ReportItem {
  uid: string;
  data: {
    date: string;
    time: string;
    type: string;
    createdAt: number;
  };
}

export default defineComponent({
  name: 'MiiImporter',
  components: {
    'ui-table': UITable
  },
  setup() {
    const headers = [
      { label: 'Fecha', key: 'date', format: '' },
      { label: 'Hora', key: 'time', format: '' },
      { label: 'Tipo', key: 'type', format: '' }
    ];
    const actions = [
      {
        label: 'DESCARGAR',
        class: 'bg-yellow-600 text-white',
        cb: (i: number) => getReportFile(i)
      }
    ];
    const items = ref<ReportItem[]>([]);

    const getReportFile = async (i: number) => {
      try {
        const report = items.value[i];
        const request = await requestFile(
          `report/file?name=${report.data.createdAt}`,
          `${report.data.type.toLowerCase()}-${moment().format('DD-MM-YYYY')}.csv`
        );
        if (request.ok) showToast('SUCCESS', 'Descargando reporte');
        else showToast('ERROR', request.errors+'');
      } catch (error) {
        console.log(error);
        showToast('ERROR', 'Error descargando reporte');
      }
    };

    onMounted(async () => {
      const request = await makeRequest<IReport[]>(
        METHODS.GET,
        '/reports',
        true
      );
      if (request.ok && request.payload?.length) {
        items.value = request.payload.map((el) => {
          return {
            uid: el._id,
            data: {
              date: moment(el.createdAt).format('DD-MM-YYYY'),
              time: moment(el.createdAt).format('HH:mm'),
              type: `Reporte ${el.descripcion}`,
              createdAt: el.createdAt
            }
          };
        });
      }
    });

    return {
      headers,
      actions,
      items
    };
  }
});
